var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.modalStatus
      ? _c(
          "div",
          {
            staticClass:
              "flex flex-wrap justify-between ph2 pv3 link light-gray bg-adori-light-gray bb b--adori-gray br2 mt2 mb relative",
          },
          [
            _vm.selectable
              ? _c(
                  "div",
                  {
                    staticClass: "w-10 flex justify-center",
                    class: [
                      _vm.uploadStatus.QUEUED,
                      _vm.uploadStatus.STARTED,
                      _vm.uploadStatus.FAILED,
                    ].includes(_vm.status)
                      ? "not-allowed"
                      : "pointer",
                    on: {
                      click: function ($event) {
                        ;[
                          _vm.uploadStatus.QUEUED,
                          _vm.uploadStatus.STARTED,
                          _vm.uploadStatus.FAILED,
                        ].includes(_vm.status)
                          ? ""
                          : _vm.handleAudioSelectionIconClicked(_vm.ad.uid)
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "f3 material-icons white inline mr2",
                        class: [
                          _vm.uploadStatus.QUEUED,
                          _vm.uploadStatus.STARTED,
                          _vm.uploadStatus.FAILED,
                        ].includes(_vm.status)
                          ? _vm.selectionStyle + " not-allowed"
                          : _vm.selectionStyle + " pointer",
                      },
                      [_vm._v(_vm._s(_vm.selectionIcon))]
                    ),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "w-20 flex justify-center" }, [
              _vm._v(_vm._s(_vm.ad.name)),
            ]),
            _c("div", { staticClass: "w-20 flex justify-center" }, [
              _c("i", { staticClass: "f5 material-icons red inline mr2" }, [
                _vm._v("query_builder"),
              ]),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.audioDuration(_vm.ad.durationMillis)) +
                  "\n    "
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "w-20 flex justify-center",
                on: {
                  click: function ($event) {
                    ;[
                      _vm.uploadStatus.QUEUED,
                      _vm.uploadStatus.STARTED,
                      _vm.uploadStatus.FAILED,
                    ].includes(_vm.status)
                      ? ""
                      : _vm.preview()
                  },
                },
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "f3 material-icons red inline mr2",
                    class: [
                      _vm.uploadStatus.QUEUED,
                      _vm.uploadStatus.STARTED,
                      _vm.uploadStatus.FAILED,
                    ].includes(_vm.status)
                      ? "not-allowed"
                      : "pointer",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.playPause
                          ? "pause_circle_outline"
                          : "play_circle_outline"
                      )
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "w-20 flex justify-center pointer" },
              [
                _c("ButtonAudioAdorify", {
                  staticClass: "mr3 mb0 w-fixed",
                  attrs: { audioUid: _vm.ad.uid },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "w-10 flex justify-center",
                on: {
                  click: function ($event) {
                    ;[
                      _vm.uploadStatus.QUEUED,
                      _vm.uploadStatus.STARTED,
                      _vm.uploadStatus.FAILED,
                    ].includes(_vm.status)
                      ? ""
                      : _vm.handleAddAudioEdit(_vm.ad)
                  },
                },
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "f5 material-icons red",
                    class: [
                      _vm.uploadStatus.QUEUED,
                      _vm.uploadStatus.STARTED,
                      _vm.uploadStatus.FAILED,
                    ].includes(_vm.status)
                      ? "not-allowed"
                      : "pointer",
                  },
                  [_vm._v("edit")]
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }