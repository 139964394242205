













































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { uploadStatus } from '@/components/Publish/publish'
import { readableMillisToSS } from '@/utils/time'
import ButtonAudioAdorify from './Buttons/ButtonAudioAdorify.vue'

@Component({
  components: {
    ButtonAudioAdorify,
  },
})
export default class TheAdList extends Vue {
  @Prop(Boolean) selectable!: boolean
  @Prop() ad!: any

  @Getter audioAds!: any
  @Getter hasLoadedUser!: any

  @Action getAudioAds!: any

  modalStatus = false
  selectedState!: boolean

  playPause = false

  audio!: any
  status = ''

  get uploadStatus() {
    return uploadStatus
  }

  created() {
    this.audio = new Audio(this.ad.audioUrl)
  }

  beforeDestroy() {
    this.audio.pause()
    this.audio.currentTime = 0
  }

  handleSubAudioEdit() {
    this.modalStatus = true
  }

  handleStatus(status: string, audio: any) {
    if (status === uploadStatus.FINISHED) {
      this.audio = new Audio(audio.audioUrl)
    }
    this.status = status
  }

  async closeModal() {
    this.modalStatus = false
  }

  get selectionIcon() {
    return this.$store.getters.isAudioSelected(this.ad.uid) ? 'check_circle' : 'radio_button_unchecked'
  }

  get selectionStyle() {
    return this.$store.getters.isAudioSelected(this.ad.uid) ? 'adori-red' : 'o-20'
  }
  audioDuration(duration: any) {
    return readableMillisToSS(duration)
  }
  handleAudioSelectionIconClicked(uid: string) {
    if (this.selectedState) {
      this.$store.dispatch('unselectAllAudios')
      this.selectedState = false
      return
    }
    if (this.selectable) {
      this.$store.dispatch('unselectAllAudios')
      this.$store.dispatch('toggleSelectAudio', uid)
      this.selectedState = true
    }
  }

  preview() {
    if (this.playPause) {
      this.playPause = false
      this.audio.pause()
    } else {
      this.playPause = true
      this.audio.play()
    }
  }

  handleAddAudioEdit(ad: any) {
    this.$store.commit('setUpdateAudioAd', ad)
  }
}
